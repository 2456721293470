import React from 'react'
import translations from 'assets/translations/translations'
import styled from 'styled-components'
import Section from 'components/Index/Section'
import MissionItem from 'components/Index/SectionMission/MissionItem'
import Care from 'assets/icons/Care'
import Complex from 'assets/icons/Complex'
import Growth from 'assets/icons/Growth'
// import Advisory from 'assets/icons/Advisory'
import Prices from 'assets/icons/Prices'
import Safety from 'assets/icons/Safety'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  display: grid;
  justify-content: center;
`

const items = [
  {
    header: translations.mainPage.sectionMission.missionItems.care.header,
    description:
      translations.mainPage.sectionMission.missionItems.care.description,
    svg: <Care />,
  },
  {
    header: translations.mainPage.sectionMission.missionItems.complex.header,
    description:
      translations.mainPage.sectionMission.missionItems.complex.description,
    svg: <Complex />,
  },
  {
    header: translations.mainPage.sectionMission.missionItems.growth.header,
    description:
      translations.mainPage.sectionMission.missionItems.growth.description,
    svg: <Growth />,
  },
  // {
  //   header: translations.mainPage.sectionMission.missionItems.advisory.header,
  //   description:
  //     translations.mainPage.sectionMission.missionItems.advisory.description,
  //   svg: <Advisory />,
  // },
  {
    header: translations.mainPage.sectionMission.missionItems.prices.header,
    description:
      translations.mainPage.sectionMission.missionItems.prices.description,
    svg: <Prices />,
  },
  {
    header: translations.mainPage.sectionMission.missionItems.safety.header,
    description:
      translations.mainPage.sectionMission.missionItems.safety.description,
    svg: <Safety />,
  },
]

const Line = styled.div`
  background-color: ${(props) => props.theme.colors.blue};
  height: 1px;
  opacity: 0.5;
  width: 100%;
  margin: 50px 0 50px 0;
  &:last-child {
    display: none;
  }
  ${MEDIA.MIN_TABLET`
    height: 2px;
    margin: 100px 0 100px 0;
    `}
`

const SectionMission = () => (
  <Section
    lineHeader={translations.mainPage.sectionMission.lineHeader}
    header={translations.mainPage.sectionMission.header}
    text={translations.mainPage.sectionMission.text}
  >
    <Container>
      {items.map(({ header, description, svg }) => (
        <>
          <MissionItem
            key={header}
            header={header}
            description={description}
            svg={svg}
          />
          <Line />
        </>
      ))}
    </Container>
  </Section>
)

export default SectionMission
