import React, { useEffect, useRef, useState } from 'react'
import SectionOne from 'components/Index/SectionOne/SectionOne'
import SectionValues from 'components/Index/SectionValues/SectionValues'
import SectionStaff from 'components/Index/SectionStaff/SectionStaff'
import SectionMission from 'components/Index/SectionMission/SectionMission'
import SectionOffer from 'components/Index/SectionOffer/SectionOffer'
import SectionNews from 'components/Index/SectionNews/SectionNews'
import SectionContact from 'components/Index/SectionContact/SectionContact'
import SectionNav from 'components/Index/SectionNav'
import SEO from 'components/SEO/SEO'
import translations from 'assets/translations/translations'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import MEDIA from 'utils/helpers/media'
import Footer from 'components/Footer/Footer'

const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  height: 300px;
  margin: auto;
  grid-auto-flow: row;
  display: none;
  align-items: center;
  opacity: ${(props) => (props.navVisible ? '1' : '0')};
  visibility: ${(props) => (props.navVisible ? 'visible' : 'hidden')};
  transition: all 0.5s ease-in-out;
  ${MEDIA.MIN_DESKTOP`
    display: grid;
    `}
`

const IndexPage = () => {
  const dispatch = useDispatch()
  const sectionOneRef = useRef(null)
  const sectionValuesRef = useRef(null)
  const sectionStaffRef = useRef(null)
  const sectionMissionRef = useRef(null)
  const sectionOfferRef = useRef(null)
  const sectionNewsRef = useRef(null)
  const sectionContactRef = useRef(null)
  const [activeRef, setActiveRef] = useState(null)
  const [navVisible, setNavVisible] = useState(false)
  const refsArray = [
    {
      ref: sectionOneRef,
      text: translations.headers.index.welcome,
      active: 'sectionOne',
    },
    {
      ref: sectionValuesRef,
      text: translations.mainPage.sectionValues.lineHeader,
      active: 'sectionValues',
    },
    {
      ref: sectionStaffRef,
      text: translations.mainPage.sectionStaff.lineHeader,
      active: 'sectionStaff',
    },
    {
      ref: sectionMissionRef,
      text: translations.mainPage.sectionMission.lineHeader,
      active: 'sectionMission',
    },
    {
      ref: sectionOfferRef,
      text: translations.mainPage.sectionOffer.lineHeader,
      active: 'sectionOffer',
    },
    // {
    //   ref: sectionNewsRef,
    //   text: translations.mainPage.sectionNews.lineHeader,
    //   active: 'sectionNews',
    // },
    {
      ref: sectionContactRef,
      text: translations.mainPage.sectionContact.lineHeader,
      active: 'sectionContact',
    },
  ]

  const setInnerHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  const scrollHandler = () => {
    if (window.pageYOffset >= sectionOneRef.current.offsetHeight) {
      setNavVisible(true)
    }
    if (window.pageYOffset === sectionOneRef.current.offsetTop) {
      setActiveRef('sectionOne')
      setNavVisible(false)
    }
    if (
      window.pageYOffset >= sectionOneRef.current.offsetHeight &&
      window.pageYOffset - 1 < sectionStaffRef.current.offsetTop
    ) {
      setActiveRef('sectionValues')
    }
    if (
      window.pageYOffset + 1 >= sectionStaffRef.current.offsetTop &&
      window.pageYOffset - 1 < sectionMissionRef.current.offsetTop
    ) {
      setActiveRef('sectionStaff')
    }
    if (
      window.pageYOffset + 1 >= sectionMissionRef.current.offsetTop &&
      window.pageYOffset - 1 < sectionOfferRef.current.offsetTop
    ) {
      setActiveRef('sectionMission')
    }
    if (
      window.pageYOffset + 1 >= sectionOfferRef.current.offsetTop &&
      window.pageYOffset - 1 < sectionContactRef.current.offsetTop
    ) {
      setActiveRef('sectionOffer')
    }
    // if (
    //   window.pageYOffset + 1 >= sectionNewsRef.current.offsetTop &&
    //   window.pageYOffset - 1 < sectionContactRef.current.offsetTop &&
    //   !(window.innerHeight + window.scrollY >= document.body.offsetHeight)
    // ) {
    //   setActiveRef('sectionNews')
    // }
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight ||
      window.pageYOffset + 1 >= sectionContactRef.current.offsetTop
    ) {
      setActiveRef('sectionContact')
    }
  }

  useEffect(() => {
    setInnerHeight()
    window.addEventListener('resize', setInnerHeight)
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('resize', setInnerHeight)
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [activeRef])

  useEffect(
    () => () => {
      dispatch({
        type: 'SET_INITIAL_LOAD',
      })
    },
    [dispatch],
  )
  return (
    <>
      <SEO title={translations.pages.index} />
      <div>
        <div ref={sectionOneRef}>
          <SectionOne verticalSectionRef={sectionValuesRef} />
        </div>
        <div ref={sectionValuesRef}>
          <SectionValues />
        </div>
        <div ref={sectionStaffRef}>
          <SectionStaff />
        </div>
        <div ref={sectionMissionRef}>
          <SectionMission />
        </div>
        <div ref={sectionOfferRef}>
          <SectionOffer />
        </div>
        {/* <div ref={sectionNewsRef}>
          <SectionNews />
        </div> */}
        <div ref={sectionContactRef}>
          <SectionContact />
        </div>
      </div>
      <Container navVisible={navVisible}>
        {refsArray.map((el) => (
          <SectionNav
            activeSection={activeRef === el.active}
            text={el.text}
            sectionRef={el.ref}
          />
        ))}
      </Container>
      <Footer />
    </>
  )
}

export default IndexPage
