/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import SectionLineHeader from 'components/UI/SectionLineHeader/SectionLineHeader'
import SectionHeader from 'components/Typography/Headers/SectionHeader'
import MarginTop from 'components/UI/MarginTop/MarginTop'
import MarginBottom from 'components/UI/MarginBottom/MarginBottom'
import NormalText from 'components/Typography/Paragraphs/NormalText'

const Section = ({ text, lineHeader, header, children, sectionRef }) => (
  <>
    <div ref={sectionRef}>
      <MarginTop />
      <SectionLineHeader>{lineHeader}</SectionLineHeader>
      <MarginTop />
      <SectionHeader weight={500}>{header}</SectionHeader>
      <MarginBottom />
      <NormalText color="black" weight={300} align="center">
        {text}
      </NormalText>
      <MarginBottom />
    </div>
    <div>{children}</div>
  </>
)

Section.propTypes = {
  lineHeader: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // eslint-disable-next-line react/require-default-props
  sectionRef: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export default Section
