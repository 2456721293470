import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  width: 170px;
  height: 50px;
  display: grid;
  place-items: center;
  color: ${(props) => props.theme.colors.blue};
  font-size: ${(props) => props.theme.fontSizes.mheadSmall};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  cursor: pointer;
  transition: all 0.1s linear;
  border: 1px ${(props) => props.theme.colors.blue} solid;

  &:hover {
    background-color: ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.white};
    border: 1px ${(props) => props.theme.colors.white} solid;
  }
  &:active {
    transform: scale(1.05);
  }
  ${MEDIA.MIN_TABLET`
    width: 230px;
  height: 70px;
  font-size: ${(props) => props.theme.fontSizes.theadSmall};
    `}
`

const ReadMore = ({ children }) => <Container>{children}</Container>

ReadMore.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export default ReadMore
