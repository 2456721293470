import React, { useState } from 'react'
import styled from 'styled-components'
import translations from 'assets/translations/translations'
import { useForm } from 'react-hook-form'
import SmallHeader from 'components/Typography/Headers/SmallHeader'
import Paragraph from 'components/Typography/Paragraphs/NormalText'
import MarginWrapper from 'components/UI/MarginWrapper/MarginWrapper'
import InputButton from 'components/UI/Buttons/InputButton'
import LinkItem from 'components/Navbar/Links/LinkItem'
import gsap from 'gsap'
import MEDIA from 'utils/helpers/media'

const Box = styled.div`
  padding: 20px;
  margin-bottom: 50px;
  background-color: ${(props) => props.theme.colors.blue};
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  height: 100%;
  margin-bottom: 50px;
  ${MEDIA.MIN_SMALLPHONE`
  margin-bottom: 100px;
    `}
  ${MEDIA.MIN_TABLET`
  margin-bottom: 100px;
    `}
  ${MEDIA.MIN_DESKTOP`
  width: initial;
  left: initial;
  right: initial;
  margin-left: initial;
  margin-right: initial;
    `}
`

const Grid = styled.div`
  display: grid;
  justify-items: center;
  grid-auto-flow: row;
  grid-row-gap: 20px;
  ${MEDIA.MIN_TABLET`
  width: 600px;
  margin: auto;
  grid-row-gap: 30px;
    `}
`

const DescContainer = styled.div`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
`

const Desc1 = styled.div`
  font-size: ${(props) => props.theme.fontSizes.msmall};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  ${MEDIA.MIN_TABLET`
  font-size: ${(props) => props.theme.fontSizes.tsmall};
    `}
`

const Desc2 = styled.div`
  font-size: ${(props) => props.theme.fontSizes.msmall};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-right: 3px;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
  }
  ${MEDIA.MIN_TABLET`
   margin-right: 5px;
  font-size: ${(props) => props.theme.fontSizes.tsmall};
    `}
`
const FormContainer = styled.form`
  width: 100%;
  display: flex;
  position: relative;
`

const Input = styled.input`
  color: ${(props) => props.theme.colors.black};
  width: 100%;
  height: 30px;
  outline: 0;
  border-width: 0 0 1px;
  border-color: ${(props) =>
    props.error ? props.theme.colors.red : props.theme.colors.white};
  background-color: white;

  &::placeholder {
    color: ${(props) => props.theme.colors.white};
    opacity: 0.5;
    font-size: ${(props) => props.theme.fontSizes.msmallplusbigger};
    font-weight: ${(props) => props.theme.fontWeights.light};
    padding: 5px;
  }
  &:focus {
    border-width: 0 0 2px;
  }
  ${MEDIA.MIN_TABLET`
  height: 40px;
    `}
`

const ErrorMessage = styled.div`
  bottom: -20px;
  font-size: ${(props) => props.theme.fontSizes.msmall};
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  position: absolute;
`

const FormSubmittedText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  text-align: center;
  transform: translate(-50%, -50%);
  font-size: ${(props) => props.theme.fontSizes.mregular};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  color: ${(props) => props.theme.colors.white};
  opacity: 0;
  display: none;
`

const ContactForm = () => {
  const { register, setError, clearErrors, handleSubmit, errors } = useForm()
  const [isDisabled, setIsDisabled] = useState(false)
  const formRef = React.useRef(null)
  const containerRef = React.useRef(null)
  const formSubmittedTextRef = React.useRef(null)
  const tl = React.useRef()

  const onSubmit = (data, e) => {
    setIsDisabled(true)
    e.preventDefault()
    fetch(`${process.env.GATSBY_BACKEND_URL_PHONE}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(() => {
        formRef.current.reset()
        tl.current.play()
        setIsDisabled(false)
      })
      .catch(() => setIsDisabled(false))
  }

  const validatePhone = ({ target }) => {
    if (!target.value) {
      clearErrors('phoneNotValid')
      return
    }
    // eslint-disable-next-line no-useless-escape
    const re = /(-| |[0-9]|\+){9,15}/
    if (re.test(target.value)) {
      clearErrors('phoneNotValid')
    } else {
      setError('phoneNotValid', {
        type: 'manual',
      })
    }
  }

  React.useEffect(() => {
    tl.current = gsap.timeline({ paused: true })
    tl.current
      .to(containerRef.current, {
        opacity: 0,
        duration: 0.5,
        ease: 'power2.inOut',
        delay: 0.5,
      })
      .to(containerRef.current, {
        visibility: 'hidden',
      })
      .to(
        formSubmittedTextRef.current,
        {
          display: 'grid',
          opacity: 1,
          duration: 0.5,
        },
        '-=0.2',
      )
      .from(
        formSubmittedTextRef.current,
        {
          y: -50,
        },
        '-=0.5',
      )
    return () => {
      tl.current.kill()
    }
  }, [])

  return (
    <Box>
      <div ref={containerRef}>
        <MarginWrapper>
          <Grid>
            <SmallHeader align="center">
              {translations.mainPage.sectionContact.form.header}
            </SmallHeader>
            <Paragraph color="white" align="center" weight={300}>
              {translations.mainPage.sectionContact.form.description}
            </Paragraph>
            <FormContainer ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <Input
                error={errors.phone || errors.phoneNotValid}
                name="phone"
                type="text"
                onChange={validatePhone}
                placeholder="Numer telefonu*"
                ref={register({ required: true })}
              />
              {errors.phoneNotValid && (
                <ErrorMessage>{translations.errors.phone}</ErrorMessage>
              )}
              <InputButton isDisabled={isDisabled} />
            </FormContainer>
            <DescContainer>
              <Desc1>
                {translations.mainPage.sectionContact.form.ps1}
                <div style={{ display: 'inline-block' }}>
                  <LinkItem pureLink link={translations.routes.privacy}>
                    <Desc2>
                      {translations.mainPage.sectionContact.form.ps2}
                    </Desc2>
                  </LinkItem>
                </div>
                {translations.mainPage.sectionContact.form.ps3}
              </Desc1>
            </DescContainer>
          </Grid>
        </MarginWrapper>
      </div>
      <FormSubmittedText ref={formSubmittedTextRef}>
        {translations.UI.formSubmitted}
      </FormSubmittedText>
    </Box>
  )
}

export default ContactForm
