import React from 'react'

export default () => (
  <svg viewBox="0 0 178.5 100.943">
    <g id="Group_73" data-name="Group 73" transform="translate(0)">
      <path
        id="Path_327"
        data-name="Path 327"
        d="M144.014,416.9h-2.65l-.4-10.221h3.046Z"
        transform="translate(-110.498 -318.772)"
        fill="#ffb8b8"
      />
      <path
        id="Path_328"
        data-name="Path 328"
        d="M314.3,669.9h-8.545v-.108a3.326,3.326,0,0,1,3.326-3.326H314.3Z"
        transform="translate(-280.107 -569.201)"
        fill="#2f2e41"
      />
      <path
        id="Path_329"
        data-name="Path 329"
        d="M224.112,416.9h2.65l.4-10.221h-3.047Z"
        transform="translate(-175.669 -318.772)"
        fill="#ffb8b8"
      />
      <path
        id="Path_330"
        data-name="Path 330"
        d="M408.085,666.461H413.3a3.326,3.326,0,0,1,3.326,3.326v.108h-8.545Z"
        transform="translate(-360.318 -569.201)"
        fill="#2f2e41"
      />
      <path
        id="Path_331"
        data-name="Path 331"
        d="M342.7,528.3a.977.977,0,0,1-.95-.761l-6.568-28.649a.315.315,0,0,0-.316-.254h0a.315.315,0,0,0-.316.257l-6.032,28.456a.977.977,0,0,1-.952.771h-2.873a.973.973,0,0,1-.971-1.03l2.14-36.066.861-.255h.016l16.916.148,3.6,36.027a.972.972,0,0,1-.89,1.064l-3.589.29Q342.742,528.3,342.7,528.3Z"
        transform="translate(-294.191 -431.485)"
        fill="#2f2e41"
      />
      <circle
        id="Ellipse_32"
        data-name="Ellipse 32"
        cx="5.309"
        cy="5.309"
        r="5.309"
        transform="translate(36.218 15.653)"
        fill="#a0616a"
      />
      <path
        id="Path_332"
        data-name="Path 332"
        d="M340.434,381.271a9.163,9.163,0,0,1-2.751-.387A6.073,6.073,0,0,1,334,377.777a.974.974,0,0,1-.072-.725c.585-2,3.415-12.386,1.61-20.643a8.751,8.751,0,0,1,1.244-6.725,8.641,8.641,0,0,1,5.6-3.769h0c.3-.058.6-.106.895-.143a8.611,8.611,0,0,1,6.973,2.294,8.823,8.823,0,0,1,2.77,6.995l-1.387,22.265a.964.964,0,0,1-.518.8C349.52,378.962,344.72,381.271,340.434,381.271Z"
        transform="translate(-302.16 -317.778)"
        fill="#ccc"
      />
      <path
        id="Path_333"
        data-name="Path 333"
        d="M327.517,419.21a2.322,2.322,0,0,0,.83-3.462l1.736-16.977h-3.752l-1.38,16.577a2.335,2.335,0,0,0,2.566,3.862Z"
        transform="translate(-294.509 -359.372)"
        fill="#a0616a"
      />
      <path
        id="Path_334"
        data-name="Path 334"
        d="M331.1,361.368h0a3.076,3.076,0,0,1,2.462,3.23l-.517,7.4a.865.865,0,0,1-1.116.766l-6.23-1.907a.865.865,0,0,1-.554-1.137l2.478-6.442A3.076,3.076,0,0,1,331.1,361.368Z"
        transform="translate(-295.264 -330.007)"
        fill="#ccc"
      />
      <path
        id="Path_335"
        data-name="Path 335"
        d="M363.137,280.5a6.518,6.518,0,0,0-7.144-4.017l1.674,1.353c-2.08.07-4.269-.079-5.754,1.379.473-.013,1.092,1,1.565.985a2.479,2.479,0,0,0-2.076,1.432,4.077,4.077,0,0,0-.241,2.593,30.128,30.128,0,0,0,1.865,3.7c-.062-2.112,4.1-7.149,6.126-6.623a5.571,5.571,0,0,0-2.572,1.953,7.544,7.544,0,0,1,4.475-.732,3.167,3.167,0,0,0,1.067.06,1.3,1.3,0,0,0,.772-2.081Z"
        transform="translate(-315.613 -263.445)"
        fill="#2f2e41"
      />
      <path
        id="Path_336"
        data-name="Path 336"
        d="M269.669,681.932H187.314a.216.216,0,1,1,0-.432h82.354a.216.216,0,0,1,0,.432Z"
        transform="translate(-187.098 -580.989)"
        fill="#3f3d56"
      />
      <rect
        id="Rectangle_1132"
        data-name="Rectangle 1132"
        width="1.297"
        height="0.216"
        transform="translate(175.69 6.268)"
        fill="#e6e6e6"
      />
      <path
        id="Path_337"
        data-name="Path 337"
        d="M693.244,245.716h-2.62V245.5h2.62Zm-5.24,0h-2.62V245.5H688Zm-5.24,0h-2.62V245.5h2.62Zm-5.24,0H674.9V245.5h2.62Zm-5.24,0h-2.62V245.5h2.62Zm-5.24,0h-2.62V245.5h2.62Zm-5.24,0h-2.62V245.5h2.62Zm-5.24,0h-2.62V245.5h2.62Zm-5.24,0H648.7V245.5h2.62Zm-5.24,0h-2.62V245.5h2.62Zm-5.24,0h-2.62V245.5h2.62Zm-5.24,0h-2.62V245.5h2.62Zm-5.24,0h-2.62V245.5h2.62Zm-5.24,0H622.5V245.5h2.62Zm-5.24,0h-2.62V245.5h2.62Zm-5.24,0h-2.62V245.5h2.62Z"
        transform="translate(-520.174 -239.232)"
        fill="#e6e6e6"
      />
      <rect
        id="Rectangle_1133"
        data-name="Rectangle 1133"
        width="1.297"
        height="0.216"
        transform="translate(87.931 6.268)"
        fill="#e6e6e6"
      />
      <rect
        id="Rectangle_1134"
        data-name="Rectangle 1134"
        width="1.297"
        height="0.216"
        transform="translate(133.107 26.371)"
        fill="#e6e6e6"
      />
      <path
        id="Path_338"
        data-name="Path 338"
        d="M650.559,338.716h-2.581V338.5h2.581Zm-5.162,0h-2.581V338.5H645.4Zm-5.162,0h-2.581V338.5h2.581Zm-5.162,0h-2.581V338.5h2.581Zm-5.162,0h-2.581V338.5h2.581Zm-5.162,0h-2.581V338.5h2.581Zm-5.162,0H617V338.5h2.581Zm-5.162,0h-2.581V338.5h2.581Z"
        transform="translate(-520.033 -312.129)"
        fill="#e6e6e6"
      />
      <rect
        id="Rectangle_1135"
        data-name="Rectangle 1135"
        width="1.297"
        height="0.216"
        transform="translate(87.931 26.371)"
        fill="#e6e6e6"
      />
      <rect
        id="Rectangle_1136"
        data-name="Rectangle 1136"
        width="1.297"
        height="0.216"
        transform="translate(107.818 35.881)"
        fill="#e6e6e6"
      />
      <path
        id="Path_339"
        data-name="Path 339"
        d="M625.465,382.716h-2.656V382.5h2.656Zm-5.311,0H617.5V382.5h2.655Zm-5.311,0h-2.656V382.5h2.656Z"
        transform="translate(-520.303 -346.619)"
        fill="#e6e6e6"
      />
      <rect
        id="Rectangle_1137"
        data-name="Rectangle 1137"
        width="1.297"
        height="0.216"
        transform="translate(87.931 35.881)"
        fill="#e6e6e6"
      />
      <path
        id="Path_340"
        data-name="Path 340"
        d="M688.871,256.51v37.018a7.033,7.033,0,0,1-7.025,7.025H606.624a7,7,0,0,1-5.224-2.334v-.331a6.789,6.789,0,0,0,5.181,2.449l13.01-13.01a1.837,1.837,0,0,1,2.6,0l6.915,6.913,16.209-16.207a1.837,1.837,0,0,1,2.6,0l9.509,9.506,31.02-31.018.214-.007.1,0Z"
        transform="translate(-511.848 -247.862)"
        fill="#0047BA"
      />
      <path
        id="Path_341"
        data-name="Path 341"
        d="M675.648,269.241H600.427a7.033,7.033,0,0,1-7.025-7.025V223.525a7.033,7.033,0,0,1,7.025-7.025h75.221a7.033,7.033,0,0,1,7.025,7.025v38.691A7.033,7.033,0,0,1,675.648,269.241Zm-75.221-52.525a6.817,6.817,0,0,0-6.809,6.809v38.691a6.816,6.816,0,0,0,6.809,6.809h75.221a6.817,6.817,0,0,0,6.809-6.809V223.525a6.817,6.817,0,0,0-6.809-6.809Z"
        transform="translate(-505.578 -216.5)"
        fill="#3f3d56"
      />
      <path
        id="Path_342"
        data-name="Path 342"
        d="M625.7,299.9l-.153-.153L638.591,286.7a1.839,1.839,0,0,1,2.6,0l6.915,6.915L664.314,277.4a1.839,1.839,0,0,1,2.6,0l9.509,9.509,31.266-31.266.153.153-31.418,31.419-9.661-9.661a1.621,1.621,0,0,0-2.293,0L648.1,293.919l-7.068-7.068a1.623,1.623,0,0,0-2.293,0Z"
        transform="translate(-530.776 -247.185)"
        fill="#3f3d56"
      />
      <circle
        id="Ellipse_33"
        data-name="Ellipse 33"
        cx="1.513"
        cy="1.513"
        r="1.513"
        transform="translate(107.601 34.476)"
        fill="#3f3d56"
      />
      <circle
        id="Ellipse_34"
        data-name="Ellipse 34"
        cx="1.513"
        cy="1.513"
        r="1.513"
        transform="translate(132.891 24.966)"
        fill="#3f3d56"
      />
      <circle
        id="Ellipse_35"
        data-name="Ellipse 35"
        cx="1.513"
        cy="1.513"
        r="1.513"
        transform="translate(175.473 4.863)"
        fill="#3f3d56"
      />
      <ellipse
        id="Ellipse_36"
        data-name="Ellipse 36"
        cx="5.683"
        cy="8.088"
        rx="5.683"
        ry="8.088"
        transform="translate(31.381 46.965)"
        fill="#3f3d56"
      />
      <ellipse
        id="Ellipse_37"
        data-name="Ellipse 37"
        cx="5.683"
        cy="8.088"
        rx="5.683"
        ry="8.088"
        transform="translate(31.381 46.965)"
        opacity="0.1"
      />
      <ellipse
        id="Ellipse_38"
        data-name="Ellipse 38"
        cx="5.683"
        cy="8.088"
        rx="5.683"
        ry="8.088"
        transform="translate(32.147 46.965)"
        fill="#3f3d56"
      />
      <ellipse
        id="Ellipse_39"
        data-name="Ellipse 39"
        cx="4.874"
        cy="6.937"
        rx="4.874"
        ry="6.937"
        transform="translate(32.955 48.116)"
        opacity="0.1"
      />
      <path
        id="Path_343"
        data-name="Path 343"
        d="M357.98,462.65v-.814a1.89,1.89,0,0,1-1.086-.355l.17-.54a1.736,1.736,0,0,0,1.022.347.783.783,0,0,0,.845-.79c0-.443-.277-.718-.8-.959-.724-.322-1.171-.693-1.171-1.395a1.268,1.268,0,0,1,1.072-1.3v-.814h.44v.782a1.727,1.727,0,0,1,.923.282l-.177.532a1.568,1.568,0,0,0-.894-.274.681.681,0,0,0-.752.693c0,.419.263.629.88.919a1.538,1.538,0,0,1,1.1,1.475,1.349,1.349,0,0,1-1.121,1.37v.839Z"
        transform="translate(-320.192 -404.256)"
        fill="#0047BA"
      />
      <path
        id="Path_344"
        data-name="Path 344"
        d="M367.067,410.984a2.282,2.282,0,0,1,.352.069l8.137-6.978-.476-2.56,3.77-1.223,1.283,4.451a1.729,1.729,0,0,1-.737,1.94L369.091,413.2a2.269,2.269,0,1,1-2.023-2.22Z"
        transform="translate(-326.196 -360.564)"
        fill="#a0616a"
      />
      <path
        id="Path_345"
        data-name="Path 345"
        d="M399.561,362.585h0a3.076,3.076,0,0,1,3.533,2l2.432,7.01a.865.865,0,0,1-.725,1.143l-6.479.694a.865.865,0,0,1-.956-.828l-.252-6.9a3.076,3.076,0,0,1,2.446-3.123Z"
        transform="translate(-351.718 -330.957)"
        fill="#ccc"
      />
    </g>
  </svg>
)
