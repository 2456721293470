import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import NormalText from 'components/Typography/Paragraphs/NormalText'
import IconContainer from 'components/Icons/IconContainer'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.textFirst ? '1fr auto' : 'auto 1fr'};
  grid-column-gap: 20px;
  align-items: center;
`

const SvgWrapper = styled.div`
  justify-self: ${(props) => (props.textFirst ? 'end' : 'start')};
  grid-column: ${(props) => (props.textFirst ? '2/3' : '1/2')};
  grid-row: 1;
  align-self: end;
  ${MEDIA.MIN_DESKTOP`
    justify-self: ${(props) => (props.textFirst ? 'start' : 'end')};
  grid-column: 1/2;
    `}
`

const TextContainer = styled.div`
  text-align: ${(props) => (props.textFirst ? 'left' : 'right')};
  grid-column: ${(props) => (props.textFirst ? '1/2' : '2/3')};
  grid-row: 1;
  align-self: center;
  ${MEDIA.MIN_DESKTOP`
    text-align: ${(props) => (props.textFirst ? 'right' : 'left')};
  grid-column: 2/3;
    `}
`

const ValueItem = ({ textFirst, svg, header, description }) => (
  <Container>
    <TextContainer textFirst={textFirst}>
      <NormalText color="blue" weight={500}>
        {header}
      </NormalText>
      <NormalText color="black" weight={300}>
        {description}
      </NormalText>
    </TextContainer>
    <IconContainer mobileWidth="125px" tabletWidth="150px">
      <SvgWrapper textFirst={textFirst}>{svg}</SvgWrapper>
    </IconContainer>
  </Container>
)

ValueItem.propTypes = {
  textFirst: PropTypes.bool.isRequired,
  svg: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default ValueItem
