import React from 'react'

const Development = () => (
  <svg viewBox="0 0 125 114.912">
    <g data-name="Group 84">
      <path
        fill="#0047BA"
        d="M37.044,104.98l67.84-36.47-1.409,5.777a1.831,1.831,0,0,0,3.558.868l2.383-9.769a1.837,1.837,0,0,0-1.345-2.213L98.3,60.79a1.831,1.831,0,0,0-.868,3.558l5.132,1.252L35.31,101.754a1.831,1.831,0,1,0,1.734,3.226Z"
        data-name="Path 422"
        transform="translate(-25.961 -50.953)"
      />
      <path
        d="M118.432,122.436h-4.559V51.156a4.409,4.409,0,0,0-4.4-4.4h-7.408V37.138h15.632a2.9,2.9,0,0,0,2.222-4.765L117,28.9l2.916-3.473a2.9,2.9,0,0,0-2.222-4.765H100.231A1.831,1.831,0,0,0,98.4,22.492v24.26H90.992a4.409,4.409,0,0,0-4.4,4.4v3.971a1.831,1.831,0,0,0,3.662,0v-3.97a.743.743,0,0,1,.742-.742h18.478a.743.743,0,0,1,.742.742v71.28H90.25v-58.7a1.831,1.831,0,0,0-3.662,0v58.7H76.143V76.774a4.409,4.409,0,0,0-4.4-4.4H53.261a4.409,4.409,0,0,0-4.4,4.4v45.663H38.412V99.255a4.409,4.409,0,0,0-4.4-4.4H15.53a4.409,4.409,0,0,0-4.4,4.4v4.008a1.831,1.831,0,0,0,3.662,0V99.255a.743.743,0,0,1,.742-.742H34.008a.743.743,0,0,1,.742.742v23.181H14.789V111.788a1.831,1.831,0,0,0-3.662,0v10.648H6.568a6.568,6.568,0,0,0,0,13.136H118.432a6.568,6.568,0,0,0,0-13.136Zm-4.648-91.672,2.276,2.711h-14V24.323h14l-2.276,2.711A2.9,2.9,0,0,0,113.784,30.764ZM52.52,76.774a.743.743,0,0,1,.742-.742H71.739a.743.743,0,0,1,.742.742v45.663H52.52V76.774Zm65.912,55.137H6.568a2.906,2.906,0,0,1,0-5.812H118.432a2.906,2.906,0,0,1,0,5.812Z"
        data-name="Path 423"
        transform="translate(0 -20.661)"
      />
    </g>
  </svg>
)

export default Development
