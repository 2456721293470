import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import translations from 'assets/translations/translations'
import ReadMore from 'components/UI/Buttons/ReadMore'
import LinkItem from 'components/Navbar/Links/LinkItem'
import SmallHeader from 'components/Typography/Headers/SmallHeader'
import NormalText from 'components/Typography/Paragraphs/NormalText'
import MEDIA from 'utils/helpers/media'

const Item = styled.div`
  background-color: ${(props) => props.theme.colors.blue};
  color: ${(props) => props.theme.colors.white};
  width: 100%;
  height: 350px;
  padding: 20px;
  display: grid;
  grid-row-gap: 20px;
  ${MEDIA.MIN_TABLET`
    height: 500px;
    grid-row-gap: 30px;
    padding: 30px;
    width: 600px;
    `}
  ${MEDIA.MIN_DESKTOP`
    height: auto;
    grid-row-gap: 30px;
    padding: 20px;
    width: auto;
    `}
`

const ButtonContainer = styled.div`
  align-self: end;
`

const OfferItem = ({ header, description }) => (
  <Item>
    <SmallHeader>{header}</SmallHeader>
    <NormalText color="white" weight={300}>
      {description}
    </NormalText>
    <ButtonContainer>
      <LinkItem link="/oferta" pureLink>
        <ReadMore>{translations.UI.readMore}</ReadMore>
      </LinkItem>
    </ButtonContainer>
  </Item>
)

OfferItem.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default OfferItem
