import React from 'react'
import translations from 'assets/translations/translations'
import styled from 'styled-components'
import Section from 'components/Index/Section'
import ValueItem from 'components/Index/SectionValues/ValueItem'
import Experience from 'assets/icons/Experience'
import Knowledge from 'assets/icons/Knowledge'
import Development from 'assets/icons/Development'
import SafetyValue from 'assets/icons/SafetyValue'
import MEDIA from 'utils/helpers/media'

const Margin = styled.div`
  margin-top: 100px;
`

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 50px;
  ${MEDIA.MIN_TABLET`
    grid-row-gap: 100px;
    `}
  ${MEDIA.MIN_DESKTOP`
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 100px;
    `}
`

const SectionValues = () => (
  <Margin>
    <Section
      text={translations.mainPage.sectionValues.text}
      lineHeader={translations.mainPage.sectionValues.lineHeader}
      header={translations.mainPage.sectionValues.header}
    >
      <Container>
        <ValueItem
          header={translations.mainPage.sectionValues.itemHeaders.experience}
          description={
            translations.mainPage.sectionValues.itemDescriptions.experience
          }
          textFirst
          svg={<Experience />}
        />
        <ValueItem
          header={translations.mainPage.sectionValues.itemHeaders.knowledge}
          description={
            translations.mainPage.sectionValues.itemDescriptions.knowledge
          }
          textFirst={false}
          svg={<Knowledge />}
        />
        <ValueItem
          header={translations.mainPage.sectionValues.itemHeaders.development}
          description={
            translations.mainPage.sectionValues.itemDescriptions.development
          }
          textFirst
          svg={<Development />}
        />
        <ValueItem
          header={translations.mainPage.sectionValues.itemHeaders.safety}
          description={
            translations.mainPage.sectionValues.itemDescriptions.safety
          }
          textFirst={false}
          svg={<SafetyValue />}
        />
      </Container>
    </Section>
  </Margin>
)

export default SectionValues
