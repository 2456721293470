import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import translations from 'assets/translations/translations'
import Section from 'components/Index/Section'
import styled from 'styled-components'
import NewsItem from 'components/News/NewsItem/NewsItem'
import ReadMore from 'components/UI/Buttons/ReadMore'
import LinkItem from 'components/Navbar/Links/LinkItem'
import MEDIA from 'utils/helpers/media'

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.blue};

  &:last-child {
    display: none;
  }
  ${MEDIA.MIN_DESKTOP`
  display: none;
    `}
`

const PageContainer = styled.div`
  max-width: 300px;
  margin: 0 auto;
  ${MEDIA.MIN_TABLET`
  max-width: 700px;
    `}
  ${MEDIA.MIN_DESKTOP`
  max-width: initial;
    `}
`

const NewsContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 30px;
  grid-template-columns: 100%;
  ${MEDIA.MIN_TABLET`
  grid-row-gap: 50px;
    `}
  ${MEDIA.MIN_DESKTOP`
  grid-auto-flow: column;
  grid-template-columns: auto auto;
  grid-column-gap: 100px;
    `}
`

const ButtonContainer = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 20px;
  ${MEDIA.MIN_TABLET`
  margin-top: 50px;
    `}
`

const SectionNews = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            id
            frontmatter {
              title
              date
              slug
              description
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const dataArray = data.allMarkdownRemark.edges
  const sortedData = dataArray.sort((a, b) => {
    const d1 = new Date(a.node.frontmatter.date)
    const d2 = new Date(b.node.frontmatter.date)
    return d2.getTime() - d1.getTime()
  })
  const limitedData = sortedData.slice(0, 2)
  return (
    <Section
      header={translations.mainPage.sectionNews.header}
      lineHeader={translations.mainPage.sectionNews.lineHeader}
      text={translations.mainPage.sectionNews.text}
    >
      <PageContainer>
        <NewsContainer>
          {limitedData.map((el) => (
            <>
              <NewsItem
                key={el.node.frontmatter.slug}
                fluidImage={
                  el.node.frontmatter.featuredImage.childImageSharp.fluid
                }
                description={el.node.frontmatter.description}
                date={el.node.frontmatter.date}
                title={el.node.frontmatter.title}
                newsLink={el.node.frontmatter.slug}
              />
              <Line />
            </>
          ))}
        </NewsContainer>
        <LinkItem pureLink link={translations.routes.news}>
          <ButtonContainer>
            <ReadMore>{translations.UI.allNews}</ReadMore>
          </ButtonContainer>
        </LinkItem>
      </PageContainer>
    </Section>
  )
}

export default SectionNews
