import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import translations from 'assets/translations/translations'
import styled from 'styled-components'
import Section from 'components/Index/Section'
import TeamImg from 'components/Index/SectionStaff/TeamImg'
import StaffItem from 'components/Index/SectionStaff/StaffItem'
import MarginTop from 'components/UI/MarginTop/MarginTop'
import MarginBottom from 'components/UI/MarginBottom/MarginBottom'
import MEDIA from 'utils/helpers/media'

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  ${MEDIA.MIN_TABLET`
    grid-row-gap: 60px;
  grid-column-gap: 60px;
    `}
  ${MEDIA.MIN_DESKTOP`
    grid-template-columns: auto auto auto auto;
  grid-template-rows: auto;
    `}
`

const TeamHeader = styled.div`
  display: none;
  font-size: ${(props) => props.theme.fontSizes.mheadregular};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  text-align: center;

  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.theadregular};
    `}
`

const BoxContainer = styled.footer`
  width: 100vw;
  position: relative;
  left: 50%;
  display: none;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  ${MEDIA.MIN_DESKTOP`
    width: initial;
  left: initial;
  right: initial;
  margin-left: initial;
  margin-right: initial;
    `}
`

const SectionStaff = () => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp(
        filter: {
          fluid: {
            originalName: {
              in: [
                "Agnieszka Wodzyńska-Kierownik działu kadr.png"
                "Katarzyna Wróblewska-Młodsza księgowa.png"
                "Marta Aleksandrowicz-Specjalista komunikacji.png"
                "Mariola Michalak-Specjalista księgowości.png"
              ]
            }
          }
        }
      ) {
        edges {
          node {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `)
  const images = data.allImageSharp.edges
  return (
    <Section
      lineHeader={translations.mainPage.sectionStaff.lineHeader}
      header={translations.mainPage.sectionStaff.header}
      text={translations.mainPage.sectionStaff.text}
    >
      <Grid>
        {images.map((el) => (
          <StaffItem
            key={el.node.fluid.originalName}
            fluidImg={el.node.fluid}
            name={el.node.fluid.originalName.split('.')[0].split('-')[0]}
            position={el.node.fluid.originalName.split('.')[0].split('-')[1]}
          />
        ))}
      </Grid>
      <MarginTop />
      <TeamHeader>{translations.mainPage.sectionStaff.team}</TeamHeader>
      <MarginBottom />
      <BoxContainer>
        <TeamImg />
      </BoxContainer>
    </Section>
  )
}

export default SectionStaff
