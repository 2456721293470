import React from 'react'

const Knowledge = () => (
  <svg viewBox="0 0 125 116.667">
    <path
      d="M296.039,126.8a21.627,21.627,0,0,0,2.545-32.841A20.448,20.448,0,0,0,283.832,88c-11.346.162-20.558,9.741-20.536,21.354a21.494,21.494,0,0,0,8.93,17.452,1.12,1.12,0,0,1,.445.918v2.781a6.247,6.247,0,0,0,1.042,11.3v2.44a6.257,6.257,0,0,0,6.25,6.25H288.3a6.257,6.257,0,0,0,6.25-6.25v-2.442a6.247,6.247,0,0,0,1.042-11.3v-2.78A1.123,1.123,0,0,1,296.039,126.8Zm-28.575-17.456c-.018-9.343,7.352-17.051,16.429-17.18l.238,0a16.327,16.327,0,0,1,11.529,4.766,17.46,17.46,0,0,1-2.041,26.482,5.3,5.3,0,0,0-2.2,4.313v1.94H276.839v-1.94a5.291,5.291,0,0,0-2.192-4.31A17.319,17.319,0,0,1,267.464,109.347ZM288.3,146.332h-8.333a2.086,2.086,0,0,1-2.083-2.083v-2.083h12.5v2.083A2.086,2.086,0,0,1,288.3,146.332ZM292.464,138H275.8a2.083,2.083,0,1,1,0-4.167h16.667a2.083,2.083,0,1,1,0,4.167Z"
      data-name="Path 410"
      transform="translate(-198.896 -75.498)"
    />
    <path
      fill="#0047BA"
      d="M352.59,127.148a9.417,9.417,0,0,1,5.427,6.152,2.083,2.083,0,1,0,4.011-1.129,13.615,13.615,0,0,0-7.861-8.88,2.083,2.083,0,1,0-1.578,3.857Z"
      data-name="Path 411"
      transform="translate(-263.978 -101.485)"
    />
    <path
      fill="#0047BA"
      d="M339.467,46.25V42.083a2.083,2.083,0,1,0-4.167,0V46.25a2.083,2.083,0,1,0,4.167,0Z"
      data-name="Path 412"
      transform="translate(-252.149 -40)"
    />
    <path
      fill="#0047BA"
      d="M287.011,59.038a2.085,2.085,0,0,0,1.9-2.931L287.217,52.3A2.083,2.083,0,0,0,283.411,54l1.695,3.807A2.083,2.083,0,0,0,287.011,59.038Z"
      data-name="Path 413"
      transform="translate(-213.639 -48.183)"
    />
    <path
      fill="#0047BA"
      d="M245.354,89.306a2.083,2.083,0,0,0,1.395-3.632l-3.1-2.788a2.084,2.084,0,1,0-2.788,3.1l3.1,2.786A2.075,2.075,0,0,0,245.354,89.306Z"
      data-name="Path 414"
      transform="translate(-181.796 -71.323)"
    />
    <path
      fill="#0047BA"
      d="M221.589,132.459a2.083,2.083,0,0,0-1.338-2.625l-3.963-1.287A2.083,2.083,0,1,0,215,132.51l3.963,1.288a2.081,2.081,0,0,0,2.625-1.338Z"
      data-name="Path 415"
      transform="translate(-162.113 -105.412)"
    />
    <path
      fill="#0047BA"
      d="M214.009,179.718l-4.144.436a2.083,2.083,0,1,0,.435,4.144l4.144-.436a2.083,2.083,0,1,0-.436-4.144Z"
      data-name="Path 416"
      transform="translate(-158 -143.324)"
    />
    <path
      fill="#0047BA"
      d="M448.551,183.862l4.144.436a2.083,2.083,0,1,0,.436-4.144l-4.144-.436a2.083,2.083,0,1,0-.436,4.144Z"
      data-name="Path 417"
      transform="translate(-334.527 -143.325)"
    />
    <path
      fill="#0047BA"
      d="M443.894,133.9a2.08,2.08,0,0,0,.644-.1l3.963-1.287a2.083,2.083,0,1,0-1.287-3.963l-3.963,1.287a2.083,2.083,0,0,0,.643,4.065Z"
      data-name="Path 418"
      transform="translate(-330.922 -105.412)"
    />
    <path
      fill="#0047BA"
      d="M422.009,88.772l3.1-2.786a2.083,2.083,0,1,0-2.788-3.1l-3.1,2.787a2.083,2.083,0,1,0,2.788,3.1Z"
      data-name="Path 419"
      transform="translate(-313.706 -71.324)"
    />
    <path
      fill="#0047BA"
      d="M382.937,59.038a2.083,2.083,0,0,0,1.9-1.236L386.536,54A2.083,2.083,0,1,0,382.73,52.3l-1.695,3.807a2.085,2.085,0,0,0,1.9,2.931Z"
      data-name="Path 420"
      transform="translate(-285.841 -48.183)"
    />
    <path
      d="M134.75,317.5H107.667v-2.083c0-3.128-4.071-5.406-5.318-6.03a2.1,2.1,0,0,0-.333-.132l-20.391-6.117c-1.29-1.14-5.9-5.142-11.542-9.2-9-6.482-15.984-9.768-20.75-9.768H32.667v-2.083A2.083,2.083,0,0,0,30.583,280h-12.5A2.083,2.083,0,0,0,16,282.083v45.833A2.083,2.083,0,0,0,18.083,330h12.5a2.083,2.083,0,0,0,2.083-2.083v-2.083H38.82c1.3.173,11.932,2.272,21.329,4.127,18.8,3.711,21.453,4.206,22.039,4.206H134.75a6.257,6.257,0,0,0,6.25-6.25V323.75A6.257,6.257,0,0,0,134.75,317.5ZM28.5,325.833H20.167V284.167H28.5Zm108.333,2.083A2.086,2.086,0,0,1,134.75,330H82.285c-1.3-.173-11.932-2.272-21.328-4.127-18.8-3.711-21.454-4.206-22.041-4.206h-6.25V288.333H49.333c7.883,0,24.225,13.149,29.857,18.215a2.087,2.087,0,0,0,.795.447l20.651,6.2a7.772,7.772,0,0,1,2.863,2.289v4.1a2.086,2.086,0,0,1-1.912,2.077l-20.263-4.053-14.29-8.166a2.083,2.083,0,0,0-2.067,3.618l14.583,8.333a2.084,2.084,0,0,0,.625.234l20.833,4.167a2.09,2.09,0,0,0,.409.04,6.261,6.261,0,0,0,5.893-4.167h27.44a2.086,2.086,0,0,1,2.083,2.083Z"
      data-name="Path 421"
      transform="translate(-16 -217.5)"
    />
  </svg>
)

export default Knowledge
