import React from 'react'
import translations from 'assets/translations/translations'
import Section from 'components/Index/Section'
import ContactForm from 'components/Index/SectionContact/ContactForm'

const SectionContact = () => (
  <Section
    lineHeader={translations.mainPage.sectionContact.lineHeader}
    header={translations.mainPage.sectionContact.header}
    text={translations.mainPage.sectionContact.text}
  >
    <ContactForm />
  </Section>
)

export default SectionContact
