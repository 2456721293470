import React from 'react'

export default () => (
  <svg viewBox="0 0 176.476 107.255" data-name="Group 71">
    <path
      fill="#3f3d56"
      d="M176.395,520.62H0v-.448H176.476Z"
      data-name="Path 264"
      transform="translate(0 -413.365)"
    />
    <path
      fill="#a0616a"
      d="M827.095,353.887l-4.464,8.837-13.347,6.068c-1.425,2.409-5.954,2.593-6.284,1.035-.37-1.747,5.5-4.56,5.5-4.56l11.163-7.551.977-6.5Z"
      data-name="Path 265"
      transform="translate(-673.067 -317.872)"
    />
    <path
      fill="#a0616a"
      d="M730.378,464.215l.823,6.586-4.774.329-.494-6.915Z"
      data-name="Path 266"
      transform="translate(-576.877 -368.898)"
    />
    <path
      fill="#2f2e41"
      d="M866.494,668.164a12.92,12.92,0,0,0,1.178-1.441c.684-.947,1.291,3.91,1.291,3.91a8.813,8.813,0,0,1,.494,2.964c-.165.988-3.951.988-4.61.823a31.142,31.142,0,0,0-3.951,0h-4.939c-4.281-1.976,0-3.293,0-3.293,1.317-.165,5.762-4.281,5.762-4.281l.988-1.811c.659-.165,1.317,2.3,1.317,2.3Z"
      data-name="Path 267"
      transform="translate(-713.652 -567.249)"
    />
    <path
      fill="#a0616a"
      d="M790.514,464.616l.823,6.586-4.775.329-.494-6.915Z"
      data-name="Path 268"
      transform="translate(-624.666 -369.216)"
    />
    <path
      fill="#2f2e41"
      d="M926.631,668.565a12.914,12.914,0,0,0,1.178-1.441c.684-.947,1.291,3.91,1.291,3.91a7.636,7.636,0,0,1,.494,2.8c-.165.988-3.951.988-4.61.823a26.791,26.791,0,0,0-3.951.165h-4.939c-4.281-1.976,0-3.293,0-3.293,1.317-.165,5.762-4.281,5.762-4.281l.988-1.811c.659-.165,1.317,2.3,1.317,2.3Z"
      data-name="Path 269"
      transform="translate(-761.441 -567.568)"
    />
    <path
      fill="#2f2e41"
      d="M879.519,445.154c.659.823.329,3.622.329,3.622s1.152,9.055.659,9.878.329,1.482.823,2.634a26.294,26.294,0,0,1,.988,3.951c2.8,2.3,2.634,12.842,2.634,12.842l.988,9.384c-.329.988-4.939,1.152-5.6.988s-2.634-14.982-2.634-14.982l-4.445-8.4s.329,22.391.329,23.379-4.445.494-5.433.494-.988-16.464-.988-16.464l-.988-4.281-5.268-19.592v-2.3l.823-1.152S878.86,444.331,879.519,445.154Z"
      data-name="Path 270"
      transform="translate(-719.103 -392.231)"
    />
    <circle
      cx="5.104"
      cy="5.104"
      r="5.104"
      fill="#a0616a"
      data-name="Ellipse 12"
      transform="translate(148.068 5.343)"
    />
    <path
      fill="#a0616a"
      d="M897.44,251.326c1.035,2.3.422,5.521-1.646,9.055l8.4-3.951-1.317-1.152.329-3.293Z"
      data-name="Path 271"
      transform="translate(-746.821 -238.492)"
    />
    <path
      fill="#0047BA"
      d="M870.041,271.887a5.268,5.268,0,0,0-2.135,3.077,29.16,29.16,0,0,0-1.253,6.967l-.4,7.079-4.939,18.769c4.281,3.622,6.75,2.8,12.513-.165s6.421.988,6.421.988.494-.165,0-.659,0,0,.494-.494,0,0-.165-.494,0-.165.165-.329-.659-1.646-.659-1.646l1.317-12.348,1.646-17.452c-1.976-2.47-7.573-4.61-7.573-4.61l-5.1,3.622c-1.646.659-.329-1.976-.329-1.976Z"
      data-name="Path 272"
      transform="translate(-719.422 -253.785)"
    />
    <path
      fill="#a0616a"
      d="M937.922,343.937l.659,9.878-8.4,12.019c0,2.8-1.482,3.457-1.482,3.457a21.768,21.768,0,0,1-1.482-2.8,3.2,3.2,0,0,1,.494-3.293l5.762-12.183-2.469-6.092Z"
      data-name="Path 273"
      transform="translate(-771.579 -312.088)"
    />
    <path
      fill="#0047BA"
      d="M943.472,292.108c2.8.988,3.293,11.689,3.293,11.689-3.293-1.811-7.244,1.152-7.244,1.152s-.823-2.8-1.811-6.421a6.292,6.292,0,0,1,1.317-5.927A4.483,4.483,0,0,1,943.472,292.108Z"
      data-name="Path 274"
      transform="translate(-779.928 -270.631)"
    />
    <path
      fill="#2f2e41"
      d="M900.821,213.808c-.65-.52-1.536.425-1.536.425l-.52-4.678a18.545,18.545,0,0,1-5.328-.13c-2.079-.52-2.4,1.884-2.4,1.884a16.686,16.686,0,0,1-.065-2.924c.13-1.17,1.819-2.339,4.808-3.119s4.548,2.6,4.548,2.6C902.4,208.906,901.471,214.328,900.821,213.808Z"
      data-name="Path 275"
      transform="translate(-742.958 -201.798)"
    />
    <path
      fill="#e6e6e6"
      d="M210.665,475.666c.561,2.227,5.346,7.237,10.283,11.941-7.614-18.805-3.275-39.386-3.275-39.386-1.414,8.083,17.961,48.76,17.961,48.76l-4.413,2.6-1.491,2.1C214.787,494.816,210.665,475.666,210.665,475.666Z"
      data-name="Path 276"
      transform="translate(-202.369 -394.958)"
    />
    <path
      fill="#e6e6e6"
      d="M0 0H62.414V12.323H0z"
      data-name="Rectangle 1122"
      transform="translate(74.635 39.007)"
    />
    <path
      fill="#e6e6e6"
      d="M0 0H62.414V12.323H0z"
      data-name="Rectangle 1123"
      transform="translate(74.635 19.536)"
    />
    <path
      fill="#e6e6e6"
      d="M0 0H62.414V12.323H0z"
      data-name="Rectangle 1124"
      transform="translate(74.635)"
    />
    <path
      fill="#fff"
      d="M540.23,204.965h59.753V195.3H540.23Z"
      data-name="Path 277"
      transform="translate(-464.265 -193.973)"
    />
    <path
      fill="#fff"
      d="M540.23,300.317h59.753v-9.662H540.23Z"
      data-name="Path 278"
      transform="translate(-464.265 -269.746)"
    />
    <path
      fill="#fff"
      d="M540.23,394.937h59.753v-9.662H540.23Z"
      data-name="Path 279"
      transform="translate(-464.265 -344.938)"
    />
    <path
      fill="#0047BA"
      d="M3.681,0A3.681,3.681,0,1,1,0,3.681,3.681,3.681,0,0,1,3.681,0Z"
      data-name="Path 386"
      transform="translate(77.515 2.401)"
    />
    <path
      fill="#0047BA"
      d="M0,0H36.328V.8H0Z"
      data-name="Path 387"
      transform="translate(89.998 4.641)"
    />
    <path
      fill="#0047BA"
      d="M0,0H36.328V.8H0Z"
      data-name="Path 388"
      transform="translate(89.998 6.722)"
    />
    <circle
      cx="3.681"
      cy="3.681"
      r="3.681"
      fill="#ccc"
      data-name="Ellipse 14"
      transform="translate(77.515 22.096)"
    />
    <path
      fill="#ccc"
      d="M0 0H36.328V0.8H0z"
      data-name="Rectangle 1127"
      transform="translate(89.998 24.337)"
    />
    <path
      fill="#ccc"
      d="M0 0H36.328V0.8H0z"
      data-name="Rectangle 1128"
      transform="translate(89.998 26.417)"
    />
    <circle
      cx="3.681"
      cy="3.681"
      r="3.681"
      fill="#ccc"
      data-name="Ellipse 15"
      transform="translate(77.515 41.567)"
    />
    <path
      fill="#ccc"
      d="M0 0H36.328V0.8H0z"
      data-name="Rectangle 1129"
      transform="translate(89.998 43.808)"
    />
    <path
      fill="#ccc"
      d="M0 0H36.328V0.8H0z"
      data-name="Rectangle 1130"
      transform="translate(89.998 45.889)"
    />
    <path
      fill="#a0616a"
      d="M309.288,356.137s-.861,5.409,2.7,4.918c0,0,14.138-3.2,14.876-2.459s5.323.989,5.084-2.121-4.838-1.321-4.838-1.321-7.13.984-8.606.246-5.655-.492-5.655-.492Z"
      data-name="Path 280"
      transform="translate(-280.669 -320.591)"
    />
    <path
      fill="#2f2e41"
      d="M322.245,447.853l-1.96,18.6s-.615,7.376-2.459,8.975c0,0,0,2.336-.615,3.073l-.861,9.712s1.229,3.565,1.106,4.18.492,2.582.369,2.828-5.9,0-5.9,0l1.229-19.793,3.811-27.538Z"
      data-name="Path 281"
      transform="translate(-282.837 -394.667)"
    />
    <path
      fill="#2f2e41"
      d="M303.453,671.592s-2.578,6.5-1.233,7.286,15.47.224,15.47.224,2.354-1.457-.336-2.466a9.234,9.234,0,0,1-3.7-1.009,21.435,21.435,0,0,1-3.139-2.578s-.112-2.466-1.009-1.906a2.3,2.3,0,0,0-1.009,1.569Z"
      data-name="Path 282"
      transform="translate(-274.817 -572.044)"
    />
    <path
      fill="#2f2e41"
      d="M283.485,667.1s-2.828,7.13-1.352,7.991,16.966.246,16.966.246,2.582-1.6-.369-2.7a10.127,10.127,0,0,1-4.057-1.106,23.5,23.5,0,0,1-3.442-2.828s-.123-2.7-1.106-2.09a2.527,2.527,0,0,0-1.106,1.721Z"
      data-name="Path 283"
      transform="translate(-258.824 -568.432)"
    />
    <path
      fill="#2f2e41"
      d="M294.2,434.252s-1.352,12.663-1.967,15.367a34.963,34.963,0,0,0-.738,5.409s-.123,3.934-1.229,5.041a3.323,3.323,0,0,0-.983,2.459s-1.106,12.417-.492,13.892,1.449,3.392.835,4.375-7.6.3-7.6-.441,0-1.352-.369-1.721,0-2.7,0-2.7-1.475-10.942-.123-14.138,1.967-11.925,1.967-11.925-.984-9.958-2.213-11.556.246-7.253.246-7.253Z"
      data-name="Path 284"
      transform="translate(-258.105 -381.318)"
    />
    <path
      fill="#a0616a"
      d="M329.2,241.424a1.653,1.653,0,0,0,.8.678,23.818,23.818,0,0,1,4.8,3.861,5.64,5.64,0,0,1,.224-3.814,1.966,1.966,0,0,0,.284-1.284,1.6,1.6,0,0,0-.623-.746,19.332,19.332,0,0,0-1.9-1.3c-.46-.275-1.407-1.009-1.935-.929a4.907,4.907,0,0,1-.355,2.019C330.415,240.071,329.25,241.549,329.2,241.424Z"
      data-name="Path 285"
      transform="translate(-296.561 -227.813)"
    />
    <circle
      cx="5.041"
      cy="5.041"
      r="5.041"
      fill="#a0616a"
      data-name="Ellipse 16"
      transform="translate(33.882 5.111)"
    />
    <path
      fill="#e6e6e6"
      d="M291.617,261.6s.861-4.3-1.6-5.778-5.286-3.2-5.286-3.2-.861-.123-1.229,1.721c0,0-7.008,1.352-7.745,7.991s.492,16.351.492,16.351a112.245,112.245,0,0,1-2.459,13.032s-1.106.984,3.811-.492,13.523,4.18,13.523,4.18.861-.861.861-1.475,1.844-19.3,2.213-21.023S292.724,261.845,291.617,261.6Z"
      data-name="Path 286"
      transform="translate(-252.449 -239.523)"
    />
    <path
      fill="#3f3d56"
      d="M332.853,208.236a2.1,2.1,0,0,1,.989-.263,1.881,1.881,0,0,0,.978-.291c.476-.366.511-1.072.859-1.561a2.688,2.688,0,0,1,2.379-.758,2.636,2.636,0,0,0,1.55-.234,2.328,2.328,0,0,0,.822-1.374,1.862,1.862,0,0,0-.152-1.834c-.315-.319-.812-.382-1.162-.662a51.234,51.234,0,0,0-5.043-1.307,4.912,4.912,0,0,0-5.73,3.11,15.213,15.213,0,0,0-.96,3.2c.23,1.449,1.512,2.6,1.6,4.067a2.5,2.5,0,0,1,1.524-1.812.477.477,0,0,1,.31-.038c.127.041.2.168.282.275.211.28.872.7,1.247.506C332.682,209.08,332.431,208.522,332.853,208.236Z"
      data-name="Path 287"
      transform="translate(-295.119 -197.566)"
    />
    <path
      fill="#3f3d56"
      d="M364.613,331.034a.561.561,0,0,0-.011.789l3.544,3.668a.56.56,0,0,0,.8.008l10.816-10.769a.56.56,0,0,0-.089-.865l-4.253-2.77a.556.556,0,0,0-.7.069Z"
      data-name="Path 288"
      transform="translate(-324.573 -293.863)"
    />
    <path
      fill="#0047BA"
      d="M379.721,335.737a.563.563,0,0,1-.074.091L368.857,346.8a.559.559,0,0,1-.8-.008l-3.621-3.821a.561.561,0,0,1-.066-.083.5.5,0,0,0,.117.643l3.544,3.668a.559.559,0,0,0,.8.008l10.816-10.769a.56.56,0,0,0,.074-.7Z"
      data-name="Path 289"
      transform="translate(-324.462 -305.571)"
    />
    <path
      fill="#fff"
      d="M381.009,326.314l-.44-.281a.419.419,0,0,1-.6.551l-2.848-1.958a.419.419,0,0,1,.111-.745l-.227-.145a.153.153,0,0,0-.191.021l-9.581,9.416a.153.153,0,0,0,0,.218l3.48,3.391a.153.153,0,0,0,.212,0l10.109-10.229a.153.153,0,0,0-.026-.237Z"
      data-name="Path 290"
      transform="translate(-326.751 -296.015)"
    />
    <path
      fill="#e6e6e6"
      d="M210.391,145.9l2.939,2.771,6.991-6.907-3.548-2.309Z"
      data-name="Path 291"
      transform="translate(-167.191 -110.818)"
    />
    <path
      fill="#3f3d56"
      d="M427.578,344.789a.1.1,0,0,0,.144,0l.611-.611a.1.1,0,0,0,0-.144.1.1,0,0,0-.144,0l-.611.611A.1.1,0,0,0,427.578,344.789Z"
      data-name="Path 292"
      transform="translate(-374.72 -312.141)"
    />
    <path
      fill="#3f3d56"
      d="M422.562,349.8a.1.1,0,0,0,.144,0l.611-.611a.1.1,0,1,0-.144-.144l-.611.611A.1.1,0,0,0,422.562,349.8Z"
      data-name="Path 293"
      transform="translate(-370.734 -316.123)"
    />
    <circle
      cx=".687"
      cy=".687"
      r=".687"
      fill="#0047BA"
      data-name="Ellipse 17"
      transform="translate(42.581 36.771)"
    />
    <path
      fill="#a0616a"
      d="M291.812,346.817s-.861,5.409,2.7,4.918c0,0,14.138-3.2,14.876-2.459s5.323.989,5.084-2.121-4.838-1.321-4.838-1.321-7.13.984-8.606.246-5.655-.492-5.655-.492Z"
      data-name="Path 294"
      transform="translate(-266.782 -313.185)"
    />
    <path
      fill="#e6e6e6"
      d="M295.3,269.4s-5.532.615-5.532,8.606v10.2l6.762-2.828s2.828-6.639,2.582-8.483S298.991,269.642,295.3,269.4Z"
      data-name="Path 295"
      transform="translate(-265.232 -252.852)"
    />
  </svg>
)
