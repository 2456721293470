import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import gsap from 'gsap'
import DesktopMenu from 'components/Navbar/Menu/DesktopMenu'
import SectionLineHeader from 'components/UI/SectionLineHeader/SectionLineHeader'
import SectionHeader from 'components/Typography/Headers/SectionHeader'
import Title from 'components/Typography/Headers/Title'
import translations from 'assets/translations/translations'
import VerticalInformer from 'components/UI/VerticalInformer/VerticalInformer'

const Section = styled.section`
  position: relative;
  display: grid;
  height: calc(var(--vh, 1vh) * 100);
  align-items: end;
  visibility: hidden;
  grid-template-rows: 50% 20%;
  @media (max-height: 600px) and (min-width: 1200px) {
    grid-template-rows: 60% 40%;
  }
`

const GridSection = styled.div`
  align-self: center;
`

// eslint-disable-next-line react/prop-types
const SectionOne = ({ verticalSectionRef }) => {
  const indexPageLoaded = useSelector((state) => state.state.indexPageLoaded)
  const sectionRef = useRef(null)
  const firstHeaderRef = useRef(null)
  const secondHeaderRef = useRef(null)
  const thirdHeaderRef = useRef(null)
  const sectionLineHeaderRef = useRef(null)
  const tl = useRef()
  const tl2 = useRef()

  useEffect(() => {
    tl2.current = gsap.timeline({ paused: true })
    tl2.current.to(sectionRef.current, {
      visibility: 'visible',
    })
    tl2.current.play()
  }, [])

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true })
    if (!indexPageLoaded) {
      tl.current
        .from(sectionLineHeaderRef.current, {
          x: 100,
          opacity: 0,
          duration: 2,
          ease: 'power3.inOut',
        })
        .from(firstHeaderRef.current, {
          x: 100,
          opacity: 0,
          duration: 0.8,
          ease: 'power3.Out',
        })
        .from(secondHeaderRef.current, {
          x: -100,
          opacity: 0,
          duration: 0.8,
          ease: 'power3.Out',
        })
        .from(thirdHeaderRef.current, {
          x: 100,
          opacity: 0,
          duration: 0.8,
          ease: 'power3.Out',
        })
      tl.current.play()
    }

    return () => {
      tl.current.kill()
    }
  }, [indexPageLoaded])

  return (
    <>
      <DesktopMenu isIndex />
      <Section ref={sectionRef}>
        <SectionLineHeader sectionLineHeaderRef={sectionLineHeaderRef}>
          {translations.headers.index.welcome}
        </SectionLineHeader>
        <Title />
        <GridSection>
          <SectionHeader
            tabletLineHeight="50px"
            lineHeight="32px"
            blue
            weight={300}
          >
            <div ref={firstHeaderRef}>
              {translations.headers.index.accounting}
            </div>
            <div ref={secondHeaderRef}>
              {translations.headers.index.advisory}
            </div>
            <div ref={thirdHeaderRef}>
              {translations.headers.index.bookkeeping}
            </div>
          </SectionHeader>
        </GridSection>
      </Section>
      <VerticalInformer verticalSectionRef={verticalSectionRef} />
    </>
  )
}

SectionOne.propTypes = {}

export default SectionOne
