import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import translations from 'assets/translations/translations'
import gsap from 'gsap'
import MEDIA from 'utils/helpers/media'
import { window } from '../../../../node_modules/lodash/_freeGlobal'

const BoxContainer = styled.footer`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  ${MEDIA.MIN_DESKTOP`
    width: initial;
  position: relative;
  left: initial;
  right: initial;
  margin-left: initial;
  margin-right: initial;
    `}
`

const Box = styled.div`
  position: absolute;
  width: 40px;
  right: 0;
  bottom: 0;
  cursor: pointer;
  visibility: hidden;
  height: 120px;
  background-color: ${(props) => props.theme.colors.blue};
  transition: width 0.2s ease-in-out;
  &:hover {
    width: 45px;
  }
  @media (min-height: 600px) {
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
  }
  ${MEDIA.MIN_TABLET`
    width: 60px;
  height: 200px;
  &:hover {
    width: 70px;
  }
    `}
`

const Text = styled.div`
  font-size: ${(props) => props.theme.fontSizes.msmall};
  color: ${(props) => props.theme.colors.white};
  writing-mode: tb-rl;
  position: absolute;
  top: 30px;
  left: 50%;
  --webkit-transform: translateX(-50%);
  transform: translateX(-50%) rotate(180deg);
  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.tsmall};
    top: 40px;
    `}
`

const Line = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  width: 2px;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 50%;
  --webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  ${MEDIA.MIN_TABLET`
    width: 3px;
  height: 90px;
    `}
`

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

// eslint-disable-next-line react/prop-types
const VerticalInformer = () => {
  const indexPageLoaded = useSelector((state) => state.state.indexPageLoaded)
  const lineRef = useRef(null)
  const boxRef = useRef(null)
  const tl = useRef({ paused: true })
  const tl2 = useRef({ paused: true })

  useEffect(() => {
    tl2.current = gsap.timeline({ paused: true })
    tl2.current.to(boxRef.current, {
      visibility: 'visible',
    })
    tl2.current.play()
  }, [])

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true })
    if (!indexPageLoaded) {
      tl.current
        .from(boxRef.current, {
          y: 50,
          opacity: 0,
          duration: 1,
          delay: 4.4,
        })
        .to(lineRef.current, {
          y: 20,
          yoyo: true,
          repeat: -1,
          duration: 0.75,
        })
      tl.current.play()
    }
    return () => {
      tl.current.kill()
    }
  }, [indexPageLoaded])

  const clickHandler = () => {
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    window.scroll({
      top: height,
      behavior: 'smooth',
    })
  }

  return (
    <BoxContainer>
      <Box onClick={clickHandler} ref={boxRef}>
        <Wrapper>
          <Text>{translations.UI.more}</Text>
          <Line ref={lineRef} />
        </Wrapper>
      </Box>
    </BoxContainer>
  )
}

VerticalInformer.propTypes = {}

export default VerticalInformer
