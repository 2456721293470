import React from 'react'

export default () => (
  <svg viewBox="0 0 289 352.305">
    <g data-name="Group 264">
      <g data-name="Group 255">
        <g data-name="Group 254">
          <path
            d="M252.884,40.01a6.873,6.873,0,1,0,6.874,6.874A6.859,6.859,0,0,0,252.884,40.01Z"
            data-name="Path 567"
            transform="translate(-108.384 -12.479)"
          />
        </g>
      </g>
      <g data-name="Group 257">
        <g data-name="Group 256">
          <path
            d="M328.119,41.286a34.444,34.444,0,0,1-34.4-34.4A6.882,6.882,0,0,0,286.833,0H94.167a6.882,6.882,0,0,0-6.881,6.881,34.444,34.444,0,0,1-34.4,34.4A6.882,6.882,0,0,0,46,48.167v112.29A199.869,199.869,0,0,0,188.524,352.015a6.88,6.88,0,0,0,3.953,0A199.891,199.891,0,0,0,335,160.457V48.167A6.882,6.882,0,0,0,328.119,41.286Zm-6.881,119.171c0,82.192-52.447,153.371-130.738,177.771A186.056,186.056,0,0,1,59.762,160.457V54.558a48.28,48.28,0,0,0,40.8-40.8H280.442a48.28,48.28,0,0,0,40.8,40.8Z"
            data-name="Path 568"
            transform="translate(-46)"
          />
        </g>
      </g>
      <g data-name="Group 259">
        <g data-name="Group 258">
          <path
            d="M315.832,76.118a61.983,61.983,0,0,1-32-32,6.881,6.881,0,0,0-6.3-4.12H230.486a6.881,6.881,0,1,0,0,13.762H273.18a75.644,75.644,0,0,0,33.01,33.01v86.161c0,67.339-40.425,125.442-103.214,148.7-62.789-23.259-103.214-81.361-103.214-148.7V86.772a75.648,75.648,0,0,0,33.01-33.01h42.694a6.881,6.881,0,0,0,0-13.762H128.421a6.882,6.882,0,0,0-6.3,4.12,61.983,61.983,0,0,1-32,32,6.883,6.883,0,0,0-4.12,6.3v90.512c0,73.773,45.628,138.068,114.681,162.5a6.88,6.88,0,0,0,4.591,0c68.995-24.416,114.681-88.672,114.681-162.5V82.421A6.882,6.882,0,0,0,315.832,76.118Z"
            data-name="Path 569"
            transform="translate(-58.476 -12.476)"
          />
        </g>
      </g>
      <g data-name="Group 261">
        <g data-name="Group 260">
          <path
            fill="#0047BA"
            d="M227.929,146a61.929,61.929,0,1,0,61.929,61.929A62,62,0,0,0,227.929,146Zm0,110.1A48.167,48.167,0,1,1,276.1,207.929,48.221,48.221,0,0,1,227.929,256.1Z"
            data-name="Path 570"
            transform="translate(-83.429 -45.538)"
          />
        </g>
      </g>
      <g data-name="Group 263">
        <g data-name="Group 262">
          <path
            fill="#0047BA"
            d="M275.913,208.015a6.883,6.883,0,0,0-9.732,0l-29.539,29.539-8.9-8.9a6.881,6.881,0,0,0-9.732,9.732l13.762,13.762a6.882,6.882,0,0,0,9.732,0l34.4-34.4A6.882,6.882,0,0,0,275.913,208.015Z"
            data-name="Path 571"
            transform="translate(-99.023 -64.252)"
          />
        </g>
      </g>
    </g>
  </svg>
)
