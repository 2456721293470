import React from 'react'
import styled from 'styled-components'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  margin-top: 50px;
  ${MEDIA.MIN_TABLET`
    margin-top: 100px;
    `}
`

const MarginTop = () => <Container />

export default MarginTop
