import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import gsap from 'gsap'
import MEDIA from 'utils/helpers/media'

const BigTitle = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.mheadXXL};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: ${(props) => props.theme.colors.blue};
  margin-top: -15px;
  ${MEDIA.MIN_TABLET`
     font-size: ${(props) => props.theme.fontSizes.theadXXL};
   `};
  ${MEDIA.MIN_DESKTOP`
     font-size: ${(props) => props.theme.fontSizes.dheadXL};
   `};
  ${MEDIA.MIN_BIGDESKTOP`
     font-size: ${(props) => props.theme.fontSizes.bdheadXL};
   `};
`
const SmallTitle = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.mprice};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  @media (min-width: 350px) {
    font-size: ${(props) => props.theme.fontSizes.mheadXL};
  }
  ${MEDIA.MIN_TABLET`
     font-size: ${(props) => props.theme.fontSizes.theadXL};
   `};
  ${MEDIA.MIN_DESKTOP`
     font-size: ${(props) => props.theme.fontSizes.dheadXL};
   `};
  ${MEDIA.MIN_BIGDESKTOP`
     font-size: ${(props) => props.theme.fontSizes.bdheadXL};
   `};
`

const Title = () => {
  const indexPageLoaded = useSelector((state) => state.state.indexPageLoaded)
  const tl = useRef()
  const smallTitleRef = useRef(null)
  const bigTitleRef = useRef(null)

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true })
    if (!indexPageLoaded) {
      tl.current
        .from(bigTitleRef.current, {
          x: 100,
          opacity: 0,
          duration: 2,
          ease: 'power3.inOut',
        })
        .from(
          smallTitleRef.current,
          {
            x: -100,
            opacity: 0,
            duration: 2,
            ease: 'power3.inOut',
          },
          '-=2',
        )
      tl.current.play()
    }

    return () => {
      tl.current.kill()
    }
  }, [indexPageLoaded])

  return (
    <div>
      <SmallTitle ref={smallTitleRef}>Kancelaria Podatkowa</SmallTitle>
      <BigTitle ref={bigTitleRef}>Michalak</BigTitle>
    </div>
  )
}

Title.propTypes = {}

export default Title
