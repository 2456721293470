import React from 'react'

export default () => (
  <svg viewBox="0 0 198.6 175">
    <g data-name="Group 72">
      <path
        fill="#e6e6e6"
        d="M0 0H0.552V36.048H0z"
        data-name="Rectangle 1138"
        transform="rotate(-46.407 99.13255267 -148.24786502)"
      />
      <path
        fill="#e6e6e6"
        d="M492.531,143.982l-.551.033.166,2.716-2.721-.033-.006.552,3.314.039Z"
        data-name="Path 346"
        transform="translate(-327.852 -104.109)"
      />
      <path
        fill="#e6e6e6"
        d="M421.058,76.246l-.006.552-2.721-.033.166,2.716-.552.033-.2-3.308Z"
        data-name="Path 347"
        transform="translate(-279.839 -51.049)"
      />
      <circle
        cx="3.035"
        cy="3.035"
        r="3.035"
        fill="#e6e6e6"
        data-name="Ellipse 40"
        transform="translate(148.358 34.964)"
      />
      <path
        fill="#e6e6e6"
        d="M0 0H36.048V0.552H0z"
        data-name="Rectangle 1139"
        transform="rotate(-43.593 80.05635457 -18.03972284)"
      />
      <path
        fill="#e6e6e6"
        d="M103.815,147.289l3.314-.039-.006-.552-2.721.033.166-2.716-.551-.033Z"
        data-name="Path 348"
        transform="translate(-69.544 -104.109)"
      />
      <path
        fill="#e6e6e6"
        d="M178.8,76.206l-.2,3.308-.551-.033.166-2.716L175.5,76.8l-.006-.552Z"
        data-name="Path 349"
        transform="translate(-117.557 -51.049)"
      />
      <circle
        cx="3.035"
        cy="3.035"
        r="3.035"
        fill="#e6e6e6"
        data-name="Ellipse 41"
        transform="translate(44.724 34.964)"
      />
      <path
        fill="#3f3d56"
        d="M571.394,197.9H525.6a2.486,2.486,0,0,1-2.483-2.483V137.483A2.486,2.486,0,0,1,525.6,135h45.8a2.486,2.486,0,0,1,2.483,2.483v57.936a2.486,2.486,0,0,1-2.483,2.483Zm-45.8-62.35a1.933,1.933,0,0,0-1.931,1.931v57.936a1.933,1.933,0,0,0,1.931,1.931h45.8a1.933,1.933,0,0,0,1.931-1.931V137.483a1.933,1.933,0,0,0-1.931-1.931Z"
        data-name="Path 350"
        transform="translate(-449.196 -135)"
      />
      <path
        fill="#0047BA"
        d="M565.485,190.18H555.4a1.243,1.243,0,0,1-1.241-1.241v-11.9A1.243,1.243,0,0,1,555.4,175.8h10.081a1.243,1.243,0,0,1,1.242,1.241v11.9A1.243,1.243,0,0,1,565.485,190.18Z"
        data-name="Path 351"
        transform="translate(-469.994 -162.332)"
      />
      <path
        fill="#ccc"
        d="M624.821,206.895H614.74a1.243,1.243,0,0,1-1.242-1.241v-11.9a1.243,1.243,0,0,1,1.242-1.241h10.081a1.243,1.243,0,0,1,1.241,1.241v11.9A1.243,1.243,0,0,1,624.821,206.895Z"
        data-name="Path 352"
        transform="translate(-509.742 -173.528)"
      />
      <path
        fill="#ff6584"
        d="M577.185,257.873H567.1a1.243,1.243,0,0,1-1.241-1.241v-11.9a1.243,1.243,0,0,1,1.241-1.241h10.081a1.243,1.243,0,0,1,1.242,1.242v11.9A1.243,1.243,0,0,1,577.185,257.873Z"
        data-name="Path 353"
        transform="translate(-477.832 -213.779)"
      />
      <path
        fill="#3f3d56"
        d="M795.309,378.359h-45.8a2.486,2.486,0,0,1-2.483-2.483V317.94a2.486,2.486,0,0,1,2.483-2.483h45.8a2.486,2.486,0,0,1,2.483,2.483v57.936A2.486,2.486,0,0,1,795.309,378.359Zm-45.8-62.35a1.933,1.933,0,0,0-1.931,1.931v57.936a1.933,1.933,0,0,0,1.931,1.931h45.8a1.933,1.933,0,0,0,1.931-1.931V317.94a1.933,1.933,0,0,0-1.931-1.931Z"
        data-name="Path 354"
        transform="translate(-599.192 -269.426)"
      />
      <path
        fill="#0047BA"
        d="M813.582,349.839H797.857a1.1,1.1,0,0,1,0-2.207h15.725a1.1,1.1,0,0,1,0,2.207Z"
        data-name="Path 355"
        transform="translate(-632.501 -288.687)"
      />
      <path
        fill="#ccc"
        d="M805.632,381.6H774.457a1.1,1.1,0,1,1,0-2.207h31.175a1.1,1.1,0,0,1,0,2.207Z"
        data-name="Path 356"
        transform="translate(-616.826 -311.641)"
      />
      <path
        fill="#ccc"
        d="M805.632,399.146H774.457a1.1,1.1,0,1,1,0-2.207h31.175a1.1,1.1,0,0,1,0,2.207Z"
        data-name="Path 357"
        transform="translate(-616.826 -324.325)"
      />
      <path
        fill="#ccc"
        d="M805.632,416.7H774.457a1.1,1.1,0,1,1,0-2.207h31.175a1.1,1.1,0,0,1,0,2.207Z"
        data-name="Path 358"
        transform="translate(-616.826 -337.01)"
      />
      <path
        fill="#ccc"
        d="M805.632,434.246H774.457a1.1,1.1,0,1,1,0-2.207h31.175a1.1,1.1,0,1,1,0,2.207Z"
        data-name="Path 359"
        transform="translate(-616.826 -349.695)"
      />
      <path
        fill="#ccc"
        d="M805.632,451.8H774.457a1.1,1.1,0,1,1,0-2.207h31.175a1.1,1.1,0,1,1,0,2.207Z"
        data-name="Path 360"
        transform="translate(-616.826 -362.38)"
      />
      <path
        fill="#ccc"
        d="M805.632,469.346H774.457a1.1,1.1,0,1,1,0-2.207h31.175a1.1,1.1,0,1,1,0,2.207Z"
        data-name="Path 361"
        transform="translate(-616.826 -375.065)"
      />
      <path
        fill="#3f3d56"
        d="M347.48,378.359h-45.8a2.486,2.486,0,0,1-2.483-2.483V317.941a2.486,2.486,0,0,1,2.483-2.483h45.8a2.486,2.486,0,0,1,2.483,2.483v57.936A2.486,2.486,0,0,1,347.48,378.359Zm-45.8-62.35a1.933,1.933,0,0,0-1.931,1.931v57.936a1.933,1.933,0,0,0,1.931,1.931h45.8a1.933,1.933,0,0,0,1.931-1.931V317.941a1.933,1.933,0,0,0-1.931-1.931Z"
        data-name="Path 362"
        transform="translate(-299.2 -269.426)"
      />
      <path
        fill="#3f3d56"
        d="M393.218,352.414H379.424a1.1,1.1,0,0,1,0-2.207h13.794a1.1,1.1,0,0,1,0,2.207Z"
        data-name="Path 363"
        transform="translate(-352.201 -290.548)"
      />
      <path
        fill="#3f3d56"
        d="M393.218,369.964H379.424a1.1,1.1,0,1,1,0-2.207h13.794a1.1,1.1,0,0,1,0,2.207Z"
        data-name="Path 364"
        transform="translate(-352.201 -303.233)"
      />
      <path
        fill="#0047BA"
        d="M337.247,355.258H327.166a1.243,1.243,0,0,1-1.241-1.242v-11.9a1.243,1.243,0,0,1,1.241-1.241h10.081a1.243,1.243,0,0,1,1.241,1.241v11.9A1.243,1.243,0,0,1,337.247,355.258Z"
        data-name="Path 365"
        transform="translate(-317.102 -284.49)"
      />
      <path
        fill="#ccc"
        d="M357.659,405.9H326.484a1.1,1.1,0,1,1,0-2.207h31.175a1.1,1.1,0,0,1,0,2.207Z"
        data-name="Path 366"
        transform="translate(-316.738 -329.206)"
      />
      <path
        fill="#ccc"
        d="M357.659,423.449H326.484a1.1,1.1,0,0,1,0-2.207h31.175a1.1,1.1,0,0,1,0,2.207Z"
        data-name="Path 367"
        transform="translate(-316.738 -341.891)"
      />
      <path
        fill="#ccc"
        d="M357.659,441H326.484a1.1,1.1,0,1,1,0-2.207h31.175a1.1,1.1,0,0,1,0,2.207Z"
        data-name="Path 368"
        transform="translate(-316.738 -354.576)"
      />
      <path
        fill="#ccc"
        d="M357.659,458.549H326.484a1.1,1.1,0,1,1,0-2.207h31.175a1.1,1.1,0,0,1,0,2.207Z"
        data-name="Path 369"
        transform="translate(-316.738 -367.261)"
      />
      <path
        fill="#ccc"
        d="M357.659,476.1H326.484a1.1,1.1,0,1,1,0-2.207h31.175a1.1,1.1,0,0,1,0,2.207Z"
        data-name="Path 370"
        transform="translate(-316.738 -379.946)"
      />
      <path
        fill="#e6e6e6"
        d="M497.895,581.06v-11.9a1.243,1.243,0,0,1,1.241-1.241h10.081a1.243,1.243,0,0,1,1.241,1.241v11.9a1.243,1.243,0,0,1-1.241,1.242H499.136a1.243,1.243,0,0,1-1.241-1.241Z"
        data-name="Path 371"
        transform="translate(-432.302 -449.349)"
      />
      <path
        fill="#0047BA"
        d="M508.895,569.06v-11.9a1.243,1.243,0,0,1,1.241-1.242h10.081a1.243,1.243,0,0,1,1.241,1.242v11.9a1.243,1.243,0,0,1-1.241,1.242H510.136a1.243,1.243,0,0,1-1.241-1.241Z"
        data-name="Path 372"
        transform="translate(-439.671 -440.636)"
      />
      <path
        fill="#a0616a"
        d="M637.837,528.655a3,3,0,0,1-.792-4.526l-3.125-22.84,6.509.32,1.022,22.278a3.013,3.013,0,0,1-3.615,4.769Z"
        data-name="Path 373"
        transform="translate(-523.423 -402.498)"
      />
      <path
        fill="#ccc"
        d="M628.878,469.747a1.223,1.223,0,0,1-.5.351l-5.85,2.233a1.229,1.229,0,0,1-1.561-.649l-5.182-11.645a4.865,4.865,0,0,1-.238-3.444,4.733,4.733,0,0,1,3.6-3.229,4.514,4.514,0,0,1,1.944.027,4.714,4.714,0,0,1,3.388,2.945l4.607,12.178A1.231,1.231,0,0,1,628.878,469.747Z"
        data-name="Path 374"
        transform="translate(-510.977 -366.552)"
      />
      <path
        fill="#a0616a"
        d="M253.617,586.233h-4.047L248.1,570.549l5.515.074Z"
        data-name="Path 375"
        transform="translate(-166.199 -415.175)"
      />
      <path
        fill="#2f2e41"
        d="M233.817,614.557h7.805v4.914H228.9a4.914,4.914,0,0,1,4.915-4.914Z"
        data-name="Path 376"
        transform="translate(-153.338 -444.655)"
      />
      <path
        fill="#a0616a"
        d="M309.688,586.383H305.64l-1.925-15.611h5.973Z"
        data-name="Path 377"
        transform="translate(-203.453 -415.324)"
      />
      <path
        fill="#2f2e41"
        d="M290.818,614.557h7.805v4.914H285.9A4.914,4.914,0,0,1,290.818,614.557Z"
        data-name="Path 378"
        transform="translate(-191.521 -444.655)"
      />
      <path
        fill="#2f2e41"
        d="M569.721,618.454l-6.357-.471a1.487,1.487,0,0,1-1.374-1.41l-1.417-29.292a1.155,1.155,0,0,0-2.286-.176l-5.913,28.855a1.487,1.487,0,0,1-1.574,1.182l-5.746-.46a1.485,1.485,0,0,1-1.36-1.628l5.331-53.307a1.485,1.485,0,0,1,1.794-1.3l20.271,4.407a1.489,1.489,0,0,1,1.17,1.479L571.317,617a1.486,1.486,0,0,1-1.482,1.458C569.8,618.458,569.759,618.457,569.721,618.454Z"
        data-name="Path 379"
        transform="translate(-462.978 -452.95)"
      />
      <circle
        cx="8.108"
        cy="8.108"
        r="8.108"
        fill="#a0616a"
        data-name="Ellipse 42"
        transform="translate(93.075 67.06)"
      />
      <path
        fill="#ccc"
        d="M562.706,483.311a7.25,7.25,0,0,1-2.408-5.568c0-11.654,10.087-37.689,10.4-38.5.031-.2.3-.28,1.693-1.223s3.412-1.089,5.988-.431a1.479,1.479,0,0,1,1.073,1.1l.479.693a1.153,1.153,0,0,0,.67.794c2.078.875,8.682,4.911,6.431,20.1-2.09,14.11-2.939,22.409-3.206,25.322a1.483,1.483,0,0,1-1.344,1.342c-1.019.091-2.767.212-4.849.212C572.81,487.147,566.189,486.5,562.706,483.311Z"
        data-name="Path 380"
        transform="translate(-474.103 -358.244)"
      />
      <path
        fill="#a0616a"
        d="M537.626,513.3a3,3,0,0,1,1.865-4.2l10.132-20.707,5.227,3.892-11.565,19.069a3.013,3.013,0,0,1-5.659,1.946Z"
        data-name="Path 381"
        transform="translate(-458.712 -392.94)"
      />
      <path
        fill="#ccc"
        d="M576.606,463.108a1.223,1.223,0,0,1-.608.015l-6.1-1.406a1.229,1.229,0,0,1-.935-1.408l2.186-12.557a4.866,4.866,0,0,1,1.721-2.992,4.733,4.733,0,0,1,4.79-.674,4.514,4.514,0,0,1,1.6,1.106,4.714,4.714,0,0,1,1.172,4.333l-2.96,12.679A1.232,1.232,0,0,1,576.606,463.108Z"
        data-name="Path 382"
        transform="translate(-479.896 -359.629)"
      />
      <path
        fill="#2f2e41"
        d="M583.371,391.592a3.494,3.494,0,0,1-.937-3.1c.283-1.479.449-2.446.48-2.8h0c.108-1.2-1.11-2.179-2.314-2.334-.047-.006-.086-.01-.118-.013l.093.088c.229.215.514.482.446.758-.041.166-.193.284-.464.36a3.933,3.933,0,0,1-4.566-1.524,3.64,3.64,0,0,1-.695-1.518,6.029,6.029,0,0,1,1.8-5.175,6.259,6.259,0,0,1,4.092-2.229,3.593,3.593,0,0,1,3.548,2.17,2.694,2.694,0,0,1,2.1-1.184,4.84,4.84,0,0,1,2.693.749,8.981,8.981,0,0,1-3.18,16.724,3.428,3.428,0,0,1-.573.048,3.339,3.339,0,0,1-2.4-1.025Z"
        data-name="Path 383"
        transform="translate(-484.087 -308.91)"
      />
      <path
        fill="#3f3d56"
        d="M484.109,763.66h64.373a.33.33,0,0,0,0-.66H484.109a.33.33,0,0,0,0,.66Z"
        data-name="Path 384"
        transform="translate(-422.846 -588.66)"
      />
    </g>
  </svg>
)
