import React from 'react'
import translations from 'assets/translations/translations'
import Carousel from 'react-elastic-carousel'
import styled from 'styled-components'
import Section from 'components/Index/Section'
import OfferItem from 'components/Index/SectionOffer/OfferItem'
import MEDIA from 'utils/helpers/media'

const items = [
  {
    header: translations.mainPage.sectionOffer.sectionItems.accounting.header,
    description:
      translations.mainPage.sectionOffer.sectionItems.accounting.description,
  },
  {
    header: translations.mainPage.sectionOffer.sectionItems.staff.header,
    description:
      translations.mainPage.sectionOffer.sectionItems.staff.description,
  },
  {
    header: translations.mainPage.sectionOffer.sectionItems.business.header,
    description:
      translations.mainPage.sectionOffer.sectionItems.business.description,
  },
  {
    header: translations.mainPage.sectionOffer.sectionItems.companies.header,
    description:
      translations.mainPage.sectionOffer.sectionItems.companies.description,
  },
  // {
  //   header: translations.mainPage.sectionOffer.sectionItems.advisory.header,
  //   description:
  //     translations.mainPage.sectionOffer.sectionItems.advisory.description,
  // },
  // {
  //   header: translations.mainPage.sectionOffer.sectionItems.other.header,
  //   description:
  //     translations.mainPage.sectionOffer.sectionItems.other.description,
  // },
]

const CarouselContainer = styled.div`
  ${MEDIA.MIN_DESKTOP`
    display: none;
    `}
`

const GridContainer = styled.div`
  display: none;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 0 50px 0 50px;
  ${MEDIA.MIN_DESKTOP`
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    width: initial;
  left: initial;
  right: initial;
  margin-left: initial;
  margin-right: initial;
    `}
`

const DotsContainer = styled.div`
  display: grid;
  margin-top: 30px;
  grid-column-gap: 40px;
  grid-auto-flow: column;
  ${MEDIA.MIN_TABLET`
    grid-column-gap: 50px;
    `}
`

const Dot = styled.div`
  background: ${(props) => (props.active ? props.theme.colors.blue : '')};
  border-radius: 50%;
  border: 1px rgba(0, 71, 186, 0.5) solid;
  width: 12px;
  height: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  ${MEDIA.MIN_TABLET`
    border: 2px rgba(0, 71, 186, 0.5) solid;
  width: 15px;
  height: 15px;
    `}
`

const SectionOffer = () => (
  <Section
    lineHeader={translations.mainPage.sectionOffer.lineHeader}
    header={translations.mainPage.sectionOffer.header}
    text={translations.mainPage.sectionOffer.text}
  >
    <CarouselContainer>
      <Carousel
        showArrows={false}
        initialActiveIndex={2}
        renderPagination={({ pages, activePage, onClick }) => (
          <DotsContainer>
            {pages.map((page) => {
              const isActivePage = activePage === page
              return (
                <Dot
                  key={page}
                  onClick={() => onClick(page)}
                  active={isActivePage}
                />
              )
            })}
          </DotsContainer>
        )}
        itemsToShow={1}
      >
        {items.map(({ header, description }) => (
          <OfferItem header={header} description={description} key={header} />
        ))}
      </Carousel>
    </CarouselContainer>
    <GridContainer>
      {items.map(({ header, description }) => (
        <OfferItem header={header} description={description} key={header} />
      ))}
    </GridContainer>
  </Section>
)

export default SectionOffer
