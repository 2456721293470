import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import MEDIA from 'utils/helpers/media'

const Container = styled.div``
const Name = styled.div`
  margin-top: 10px;
  font-size: ${(props) => props.theme.fontSizes.mheadSmall};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.theadSmall};
    `}
`

const Position = styled.div`
  margin-top: -5px;
  font-size: ${(props) => props.theme.fontSizes.msmallplusbigger};
  font-weight: ${(props) => props.theme.fontWeights.light};
  display: table;
  border-bottom: 1px ${(props) => props.theme.colors.blue} solid;
  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.tsmallplusbigger};
    `}
`

const ImageContainer = styled.div`
  position: relative;
  &::before {
    transition: all 0.2s ease-in-out;
    content: '';
    position: absolute;
    background-color: ${(props) => props.theme.colors.blue};
    bottom: 0;
    z-index: 1;
    opacity: 0.4;
    height: 0%;
    width: 100%;
  }
  &:hover:before {
    height: 100%;
  }
`

const StaffItem = ({ fluidImg, name, position }) => (
  <div>
    <Container>
      <ImageContainer>
        <Img fluid={fluidImg} />
      </ImageContainer>
      <Name>{name}</Name>
      <Position>{position}</Position>
    </Container>
  </div>
)

StaffItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fluidImg: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
}

export default StaffItem
