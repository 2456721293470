/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import MEDIA from 'utils/helpers/media'

const GridItem = styled.div`
  position: relative;
  cursor: pointer;
  text-align: right;
  font-size: ${(props) => props.theme.fontSizes.mregular};
  text-transform: uppercase;
  height: 100%;
  display: grid;
  font-weight: ${(props) =>
    props.activeSection
      ? props.theme.fontWeights.medium
      : props.theme.fontWeights.light};
  align-content: center;
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 4px;
    background-color: ${(props) =>
      props.activeSection ? props.theme.colors.white : props.theme.colors.blue};
    right: -10px;
  }
  &:hover {
    font-weight: ${(props) => props.theme.fontWeights.medium};
  }
  ${MEDIA.MIN_BIGGERDESKTOP`
    font-size: ${(props) => props.theme.fontSizes.tregular}
    `}
`

const clickHandler = (reference) => {
  reference.current.scrollIntoView({
    behavior: 'smooth',
  })
}

const SectionNav = ({ sectionRef, text, activeSection }) => (
  <GridItem
    activeSection={activeSection}
    onClick={() => clickHandler(sectionRef)}
  >
    {text}
  </GridItem>
)

export default SectionNav
