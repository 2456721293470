import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MEDIA from 'utils/helpers/media'

const SectionHeader = styled.div`
  font-size: ${(props) => props.theme.fontSizes.mheadsectionsmall};
  font-weight: ${(props) =>
    props.weight ? props.weight : props.theme.fontWeights.bold};
  color: ${(props) =>
    props.blue ? props.theme.colors.blue : props.theme.colors.black};
  line-height: ${(props) => props.lineHeight};
  ${MEDIA.MIN_PHONE`
     font-size: ${(props) => props.theme.fontSizes.mheadsection};
   `};
  ${MEDIA.MIN_TABLET`
  line-height: ${(props) => props.tabletLineHeight};
     font-size: ${(props) => props.theme.fontSizes.theadsection};
   `};
  ${MEDIA.MIN_DESKTOP`
  line-height: ${(props) => props.tabletLineHeight};
     font-size: ${(props) => props.theme.fontSizes.dheadsection};
   `};
  ${MEDIA.MIN_BIGDESKTOP`
  line-height: ${(props) => props.tabletLineHeight};
     font-size: ${(props) => props.theme.fontSizes.bdheadsection};
   `};
`

const Section = ({ children, lineHeight, blue, weight, tabletLineHeight }) => (
  <SectionHeader
    tabletLineHeight={tabletLineHeight}
    lineHeight={lineHeight}
    blue={blue}
    weight={weight}
  >
    {children}
  </SectionHeader>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  weight: PropTypes.oneOfType([300, 400, 500, 600, 800]),
  lineHeight: PropTypes.string,
  tabletLineHeight: PropTypes.string,
  blue: PropTypes.bool,
}

Section.defaultProps = {
  weight: 400,
  lineHeight: '',
  tabletLineHeight: '',
  blue: false,
}

export default Section
