import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import IconContainer from 'components/Icons/IconContainer'
import NormalText from 'components/Typography/Paragraphs/NormalText'
import SmallHeader from 'components/Typography/Headers/SmallHeader'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  grid-row-gap: 20px;
  width: 300px;
  ${MEDIA.MIN_TABLET`
    width: 100%;
    grid-row-gap: 50px;
    `}
`
const MissionItem = ({ header, svg, description }) => (
  <>
    <Container>
      <SmallHeader white={false}>{header}</SmallHeader>

      <IconContainer
        mobileWidth="200px"
        tabletWidth="400px"
        desktopWidth="600px"
      >
        {svg}
      </IconContainer>

      <NormalText color="black" weight={300} align="center">
        {description}
      </NormalText>
    </Container>
  </>
)

MissionItem.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  svg: PropTypes.node.isRequired,
}

export default MissionItem
