import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const TeamImg = () => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp(filter: { fluid: { originalName: { eq: "team.jpg" } } }) {
        edges {
          node {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return <Img fluid={data.allImageSharp.edges[0].node.fluid} />
}

export default TeamImg
